/*LIBRARY MODULE*/
import React, { Component } from "react";
import { connect } from "react-redux";
import Menu from "@mui/material/Menu";

/*PERSONAL COMPONENT*/
import Modal from "../common_modal/Modal";
import SINI_AI from "./SINI_AI";
import SINI_DEMOGRAPHY from "./SINI_DEMOGRAPHY";
import SINI_ISOCHRONE from "./SINI_ISOCHRONE";
import SINI_PEOPLE_SPENDING from "./SINI_PEOPLE_SPENDING";
import SINI_LAND from "./SINI_LAND";
import SINI_DISASTER_RISK from "./SINI_DISASTER_RISK";
import SINI_RDTR from "./SINI_RDTR";
import SINI_POI from "./SINI_POI";
import SINI_DEMOGRAPHY_FULL from "../sini_v2_full/SINI_DEMOGRAPHY_FULL";
import SINI_PEOPLE_SPENDING_FULL from "../sini_v2_full/SINI_PEOPLE_SPENDING_FULL";
import SINI_LAND_FULL from "../sini_v2_full/SINI_LAND_FULL";
import SINI_DISASTER_RISK_FULL from "../sini_v2_full/SINI_DISASTER_RISK_FULL";
import SINI_RDTR_FULL from "../sini_v2_full/SINI_RDTR_FULL";
import SINI_POI_FULL from "../sini_v2_full/SINI_POI_FULL";
import SINI_CHAT_AI_FULL from "../sini_v2_full/SINI_CHAT_AI_FULL";
import SINI_AI_PAGE from "../sini_v2_full/SINI_AI_PAGE";
import SINI_HISTORY_FLY from "./SINI_HISTORY_FLY";

/*REDUX FUNCTION*/
import { set_value_toolbox } from "../../App/actions/toolboxActions";
import {
  set_value_sini,
  fly_init_update,
  get_ai_parent_list,
  get_ai_child_list,
  get_ai_parent_object,
} from "../../App/actions/sini_v2";
import { set_value_user } from "../../App/actions/authActions";

/*PICTURE ASSET*/
import ICON_CHOOSE_MAP from "../../Assets/jsx/ICON_CHOOSE_MAP";
import ICON_CHAT_AI from "../../Assets/jsx/ICON_CHAT_AI";
import ICON_FLY_SINI from "../../Assets/jsx/ICON_FLY_SINI";
import SINI_ILUSTRATION from "../../Assets/jsx/SINI_ILUSTRATION";

/*GENERAL FUNCTION & DATA*/
import dict from "../../Data/dict.json";
import domain_list from "../../Data/domain_list";

/*NON IMPORT*/

class PARENT_SINI extends Component {
  state = {
    anchor: null,
    modalLogin: false,
    modal_history: false,
    modal_rdtr: false,
  };

  componentDidMount() {
    const { isAuthenticated, user } = this.props.auth;
    const { license_user_status, license_group_status } =
      this.props.license_reducer;
    const quota_access = user?.quota_access || 0;
    if (
      isAuthenticated &&
      ((license_user_status?.is_has_license &&
        license_group_status?.is_has_license) ||
        quota_access > 0)
    ) {
      this.props.get_ai_parent_list();
    }
  }

  toggle_sini_visible = () => {
    this.props.set_value_sini({
      key: "is_sini_visible",
      value: !this.props.sini.is_sini_visible,
    });
  };

  toggle_error_limitation = () => {
    this.props.set_value_sini({
      key: "modal_error_limitation",
      value: !this.props.sini.modal_error_limitation,
    });
  };

  toggle_error_bill_ai = () => {
    this.props.set_value_sini({
      key: "modal_error_bill_ai",
      value: !this.props.sini.modal_error_bill_ai,
    });
  };

  toggle_rdtr = (e) => {
    this.setState({ modal_rdtr: !this.state.modal_rdtr });
    if (e) {
      this.setState({ anchor: e.currentTarget });
    }
  };

  toggle_payment_universal = () => {
    const { modal_payment_universal } = this.props.auth;
    this.props.set_value_user(
      "modal_payment_universal",
      !modal_payment_universal
    );
  };

  toggle_quota_access = () => {
    const { modal_quota_access } = this.props.auth;
    this.props.set_value_user("modal_quota_access", !modal_quota_access);
  };

  toggle_login = () => {
    const { modal_login } = this.props.auth;
    this.props.set_value_user("modal_login", !modal_login);
  };

  activate_map = (e) => {
    this.setState({
      modal_rdtr: false,
    });
    const { isAuthenticated, quota_access } = this.props.auth;
    const quota_access_sini_ai = this.props.auth.quota_access_sini_ai || 0;
    const { license_user_status, license_group_status } =
      this.props.license_reducer;
    const payment_type = license_user_status?.payment_type;
    const logic_1 = !["license_1", "license_2", "license_3"].includes(
      payment_type
    );
    const logic_2 = !license_group_status?.is_has_license;
    const logic_3 = quota_access <= 0;
    const logic_4 = quota_access_sini_ai <= 0;
    if (!isAuthenticated) {
      this.toggle_login();
    } else if (logic_1 && logic_2 && logic_3 && logic_4) {
      this.toggle_payment_universal();
    } else {
      const domain = this?.props?.auth?.domain || "";
      const name = e.target.name;
      if (name === "sini_general" || name === "sini_rdtr") {
        this.props.set_value_sini({
          key: "sini_data_mode",
          value: name,
        });
        this.props.set_value_toolbox({
          key: "draw_toolbox_status",
          value: false,
        });
        this.props.set_value_sini({
          key: "sini_choose_map_active",
          value: true,
        });
        this.props.set_value_sini({
          key: "mode_draw",
          value: "draw_point",
        });
      } else if (domain_list?.[domain]?.show_rdtr) {
        this.toggle_rdtr(e);
      } else {
        this.props.set_value_toolbox({
          key: "draw_toolbox_status",
          value: false,
        });
        this.props.set_value_sini({
          key: "sini_choose_map_active",
          value: true,
        });
        this.props.set_value_sini({
          key: "mode_draw",
          value: "draw_point",
        });
      }
    }
  };

  toggle_ai_page = () => {
    const { isAuthenticated } = this.props.auth;
    if (!isAuthenticated) {
      this.toggle_login();
    } else {
      this.props.set_value_sini({
        key: "modal_ai_page",
        value: !this.props.sini.modal_ai_page,
      });
    }
  };

  toggle_history = (e) => {
    const { isAuthenticated } = this.props.auth;
    if (!isAuthenticated) {
      this.toggle_login();
    } else {
      this.setState({ modal_history: !this.state.modal_history });
      if (e) {
        this.setState({ anchor: e.currentTarget });
      }
    }
  };

  on_cancel_activate_map = () => {
    this.props.set_value_sini({
      key: "sini_choose_map_active",
      value: false,
    });
    this.props.set_value_sini({
      key: "mode_draw",
      value: "simple_select",
    });
  };

  on_toggle_zoom = () => {
    this.props.set_value_sini({
      key: "is_stop_zoom",
      value: !this.props.sini.is_stop_zoom,
    });
  };

  render() {
    //local storage
    const language = localStorage?.language || "ina";

    //local state
    const { anchor, modal_history, modal_rdtr } = this.state;

    //global props
    const {
      request_id_active,
      sini_v2_list,
      ai_parent_detail_list,
      sini_choose_map_active,
      mode_draw,
      sini_menu_active,
      is_stop_zoom,
      error_limitation,
      modal_ai_page,
      modal_error_limitation,
      modal_error_bill_ai,
      is_sini_visible,
    } = this.props.sini;
    const { loading_item_array } = this.props.loading_reducer;
    const quota_access = this.props.auth.quota_access || 0;
    const quota_access_sini_ai = this.props.auth.quota_access_sini_ai || 0;
    const { license_user_status, license_group_status } =
      this.props.license_reducer;
    const domain = this?.props?.auth?.domain || "";

    //content
    const show_rdtr = domain_list?.[domain]?.show_rdtr;
    const merge_array = [...sini_v2_list, ...ai_parent_detail_list];
    const sini_object = merge_array.find(
      (item) => item.request_id === request_id_active
    );
    let content;
    if (
      sini_choose_map_active &&
      !loading_item_array.includes("sini_load_parent") &&
      mode_draw !== "simple_select"
    ) {
      content = (
        <main>
          <section style={{ textAlign: "center", marginTop: "30px" }}>
            <SINI_ILUSTRATION />
            <br />
            <br />
            <div className="text_bold">
              {dict?.["Select the click location on the map"]?.[language]}
            </div>
            <br />
            <button
              className="button background_grey"
              onClick={this.on_cancel_activate_map}
            >
              Cancel
            </button>
          </section>
        </main>
      );
    } else if (sini_object?.demography_one || sini_object?.rdtr_one) {
      if (sini_menu_active === "") {
        content = (
          <section>
            <SINI_AI />
            <SINI_DEMOGRAPHY />
            <SINI_ISOCHRONE />
            <SINI_PEOPLE_SPENDING />
            <SINI_LAND />
            <SINI_DISASTER_RISK />
            <SINI_POI />
            {show_rdtr && <SINI_RDTR />}
            {sini_menu_active === "" && (
              <button
                className="button background_grey"
                onClick={this.on_toggle_zoom}
                style={{ marginBottom: "50px" }}
              >
                {is_stop_zoom ? "Zoom tidak aktif" : "Zoom aktif"}
              </button>
            )}
          </section>
        );
      } else {
        if (sini_menu_active === "demography_one") {
          content = <SINI_DEMOGRAPHY_FULL />;
        } else if (sini_menu_active === "people_spending") {
          content = <SINI_PEOPLE_SPENDING_FULL />;
        } else if (sini_menu_active === "landvalue") {
          content = <SINI_LAND_FULL />;
        } else if (sini_menu_active === "flood") {
          content = <SINI_DISASTER_RISK_FULL />;
        } else if (sini_menu_active === "rdtr") {
          content = <SINI_RDTR_FULL />;
        } else if (sini_menu_active === "poi") {
          content = <SINI_POI_FULL />;
        } else if (sini_menu_active === "post_ai_chat") {
          content = <SINI_CHAT_AI_FULL />;
        } else {
          content = <SINI_DEMOGRAPHY_FULL />;
        }
      }
    } else {
      content = (
        <section
          style={{
            textAlign: "center",
            marginTop: "30px",
          }}
        >
          <SINI_ILUSTRATION />
          <br />
          <br />
          <div className="text_bold" style={{ marginBottom: "10px" }}>
            {dict?.["Click the plus button to get started SINI"]?.[language]}
          </div>
          <button
            id="blue"
            className="button_circle"
            style={{ fontSize: "50px", marginBottom: "15px" }}
            onClick={this.activate_map}
            data-mapid="clickAddSini"
          >
            <div className="button_circle_inside">+</div>
          </button>
        </section>
      );
    }

    const modal_history_content = modal_history && (
      <Menu
        id="basic-menu"
        anchorEl={anchor}
        open={modal_history}
        onClose={this.toggle_history}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <div
          style={{
            padding: "7px 15px 0px 15px",
            width: "250px",
          }}
        >
          <SINI_HISTORY_FLY toggle_history={this.toggle_history} />
        </div>
      </Menu>
    );

    const modal_ai_page_content = modal_ai_page && (
      <Modal
        modalSize="full"
        is_hide="hide"
        backgroundColor="rgba(255, 255, 255, 1);"
        use_blur={false}
        blur_value="5px"
        borderRadius="30px"
        isOpen={modal_ai_page}
        onClose={this.toggle_ai_page}
      >
        <div className="box-body" id="box-body">
          <SINI_AI_PAGE />
        </div>
      </Modal>
    );

    const modal_rdtr_content = modal_rdtr && (
      <Menu
        id="basic-menu"
        anchorEl={anchor}
        open={modal_rdtr}
        onClose={this.toggle_rdtr}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <div
          style={{
            padding: "7px 15px 0px 15px",
          }}
        >
          <button
            className="button_big button_white margin_bottom"
            name="sini_rdtr"
            onClick={this.activate_map}
          >
            KALKULATOR RDTR
          </button>
          <br />
          <button
            className="button_big button_white margin_bottom"
            name="sini_general"
            onClick={this.activate_map}
          >
            SINI
          </button>
        </div>
      </Menu>
    );

    const modal_error_limitation_content = modal_error_limitation && (
      <Modal
        modalSize="small"
        is_hide="hide"
        backgroundColor="rgba(255, 255, 255, 1);"
        use_blur={false}
        blur_value="5px"
        borderRadius="30px"
        isOpen={modal_error_limitation}
        onClose={this.toggle_error_limitation}
      >
        <div className="box-body" id="box-body">
          <h1>{error_limitation?.[language]}</h1>
          <br />
          <button onClick={this.toggle_quota_access} className="button">
            Beli akses sekarang
          </button>
        </div>
      </Modal>
    );

    const modal_error_bill_ai_content = modal_error_bill_ai && (
      <Modal
        modalSize="small"
        is_hide="hide"
        backgroundColor="rgba(255, 255, 255, 1);"
        use_blur={false}
        blur_value="5px"
        borderRadius="30px"
        isOpen={modal_error_bill_ai}
        onClose={this.toggle_error_bill_ai}
      >
        <div className="box-body" id="box-body">
          <h1>{error_limitation?.[language]}</h1>
        </div>
      </Modal>
    );

    return (
      <main style={{ overflowX: "hidden" }}>
        {modal_rdtr_content}
        {modal_ai_page_content}
        {modal_history_content}
        {modal_error_limitation_content}
        {modal_error_bill_ai_content}
        {sini_menu_active === "" && (
          <>
            <section className="parent_menu">
              <div
                className="container_light margin_right margin_bottom inline_block button_white noselect"
                style={{ width: "80px", minHeight: "60px" }}
                onClick={this.activate_map}
              >
                <ICON_CHOOSE_MAP />
                <br />
                <p className="text_small">
                  {show_rdtr
                    ? "Kalkulator RDTR"
                    : dict?.["Click on map"]?.[language]}
                </p>
              </div>
              <div
                className="container_light margin_right margin_bottom inline_block button_white noselect"
                style={{ width: "80px", minHeight: "60px" }}
                onClick={this.toggle_ai_page}
              >
                <ICON_CHAT_AI />
                <br />
                <p className="text_small">
                  {dict?.["AI chat history"]?.[language]}
                </p>
              </div>
              <div
                className="container_light margin_right margin_bottom inline_block button_white noselect"
                style={{ width: "80px", minHeight: "60px" }}
                onClick={this.toggle_history}
              >
                <ICON_FLY_SINI />
                <br />
                <p className="text_small">
                  {dict?.["History shortcut"]?.[language]}
                </p>
              </div>
            </section>
            {!license_user_status?.is_has_license &&
              !license_group_status?.is_has_license && (
                <>
                  <section className="badge_small background_blue margin_bottom margin_right">
                    Kuota akses: {quota_access}
                  </section>
                  <section className="badge_small background_blue margin_bottom margin_right">
                    Kuota trial: {quota_access_sini_ai}
                  </section>
                </>
              )}
            <table>
              <tbody>
                <tr>
                  <td onClick={this.toggle_sini_visible}>
                    <button
                      className={
                        is_sini_visible ? "checklist_active" : "checklist_not"
                      }
                    />
                  </td>
                  <td
                    onClick={this.toggle_sini_visible}
                    className="cursor_pointer text_medium"
                  >
                    Tampilkan SINI
                  </td>
                </tr>
              </tbody>
            </table>
          </>
        )}
        {loading_item_array.includes("get_ai_parent_object") && (
          <section className="container_light">
            {dict?.["Loading"]?.[language]}...
          </section>
        )}
        {content}
      </main>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  map: state.map,
  license_reducer: state.license_reducer,
  sini: state.sini,
  loading_reducer: state.loading_reducer,
});

export default connect(mapStateToProps, {
  set_value_sini,
  set_value_toolbox,
  fly_init_update,
  set_value_user,
  get_ai_parent_list,
  get_ai_child_list,
  get_ai_parent_object,
})(PARENT_SINI);
