/*LIBRARY MODULE*/
import React, { Component } from "react";
import { Resizable } from "re-resizable";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

/*PERSONAL COMPONENT*/
import LayerDetailStatistic from "./LayerDetailStatistic";

/*REDUX FUNCTION*/
import { setStatisticMode } from "../../App/actions/layerNewActions";
import { set_value_properties } from "../../App/actions/propertiesActions";

/*PICTURE ASSET*/
import icon_data from "../../Assets/svg/icon_data_new.svg";
import icon_sini from "../../Assets/svg/icon_sini.svg";
import icon_toolbox from "../../Assets/svg/icon_tool_new.svg";

/*GENERAL*/
import isEmpty from "../../App/validation/is-empty";
import dict from "../../Data/dict.json";
import { connect } from "react-redux";
import LayerList from "../viewer_bottom_bar/LayerList";
import { domain_bbws_maluku_river_schema } from "../../App/validation/features_domain";
// import is_not_empty from "../../App/validation/is_not_empty";

const tabs = [
  {
    value: "data",
    label: "DATA",
    icon: icon_data,
    alt: "Icon Data",
  },
  {
    value: "sini",
    label: "SINI",
    icon: icon_sini,
    alt: "Icon Sini",
  },
  {
    value: "toolbox",
    label: "TOOLBOX",
    icon: icon_toolbox,
    alt: "Icon Toolbox",
  },
  {
    value: "river_schema",
    label: "Skema sungai",
    icon: icon_toolbox,
    alt: "Icon Skema Sungai",
  },
];

class RIGHT_SIDEBAR extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tableComponent: props.tableComponent || { height: 0 },
      statusBarComponent: {
        width: 0,
      },
    };
  }

  setStatisticModeFunc = (event, value) => {
    this.props.setStatisticMode(value);
    this.props.set_value_properties({
      key: "sidebar_right_status",
      value: true,
    });
  };

  componentDidMount = () => {
    if (document.getElementById("statistic_resume_box")) {
      const width = document.getElementById("statistic_resume_box").offsetWidth;
      localStorage.setItem("rightbar_width", width);
    }
  };

  setStatusBarComponent = () => {
    const width = document.getElementById("statistic_resume_box").offsetWidth;
    this.setState({
      tableComponent: {
        ...this.state.statusBarComponent,
        width: width,
      },
    });
    localStorage.setItem("rightbar_width", width);
  };

  render() {
    //local storage
    const isMobile = window.innerWidth <= 650;

    //local state

    //global props
    const { statistic_mode } = this.props.layer;
    const { domain } = this.props.auth;

    let final_tabs = tabs;
    if (!domain_bbws_maluku_river_schema.includes(domain)) {
      final_tabs = tabs.filter((item) => item.value !== "river_schema");
    }

    //content
    const main_content = (
      <>
        <Tabs
          style={{
            margin: "10px 15px 0 15px",
            padding: "5px",
            height: isMobile ? "65px" : "80px",
            paddingTop: 0,
          }}
          value={statistic_mode}
          onChange={this.setStatisticModeFunc}
          aria-label="secondary tabs example"
          centered
        >
          {final_tabs?.map((tab) => (
            <Tab
              key={tab.value}
              value={tab.value}
              label={tab.label}
              iconPosition={isMobile ? "start" : "top"}
              icon={
                <div className="border w_35 h_35 rounded_5 center_perfect">
                  <img
                    className={"img-rounded w_30 h_30"}
                    src={tab.icon}
                    alt={tab.alt}
                  />
                </div>
              }
              data-mapid="clickTabIcon"
            />
          ))}
        </Tabs>

        {statistic_mode === "data" && isMobile && (
          <section className="margin_20">
            <LayerList />
          </section>
        )}

        <main
          style={{
            margin: "10px 0px 0px 1.5rem",
            paddingRight: "50px",
            overflowY: "auto",
            height: `calc(${window.innerHeight}px - 100px)`,
          }}
        >
          <div
            style={{
              width: "100%",
            }}
          >
            {this.props.loadingList ||
            this.props.loadingDetail ||
            (isEmpty(this.props.geo_project) &&
              this.props.geo_project_link !== "data") ? (
              <div className="button_standard">
                {dict["Loading GIS data"][this.props.language]} ...
              </div>
            ) : (
              <LayerDetailStatistic
                geocoderContainerRef={this.props.geocoderContainerRef || null}
                geo_project_link={this.props.geo_project_link}
              />
            )}
            <div
              style={{
                height: `${
                  this.props.tableComponent.height +
                  (this.props.extraHeight || 0)
                }px`,
              }}
            />
          </div>
        </main>
      </>
    );

    return (
      <>
        {!isMobile ? (
          <Resizable
            id="statistic_resume_box"
            onResizeStop={(e, direction, ref, d) => {
              this.setStatusBarComponent(d);
            }}
            style={{
              zIndex: 1,
              position: "absolute",
              top: 0,
              right: "43px",
              backgroundColor: "#fff",
              height: "100vh",
              display: "flex",
              flexDirection: "column",
            }}
            defaultSize={{ width: 420, height: "100vh" }}
            minWidth={420}
            maxWidth="100vw"
          >
            {main_content}
          </Resizable>
        ) : (
          <div
            id="statistic_resume_box"
            style={{
              zIndex: 2,
              position: "absolute",
              top: 0,
              right: 0,
              backgroundColor: "white",
              width: "100%",
              paddingTop: "15px",
              maxWidth: "100vw",
            }}
          >
            {main_content}
          </div>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  layer: state.layer,
  auth: state.auth,
});

export default connect(mapStateToProps, {
  setStatisticMode,
  set_value_properties,
})(RIGHT_SIDEBAR);
