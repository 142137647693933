/*LIBRARY MODULE*/
import React, { Component } from "react";
import { connect } from "react-redux";
import Menu from "@mui/material/Menu";

/*PERSONAL COMPONENT*/
import ProgressBar from "../common_spinner/ProgressBar";
import SINI_POINT_CONFIG from "./SINI_POINT_CONFIG";

/*REDUX FUNCTION*/
import { set_value_sini } from "../../App/actions/sini_v2";

/*PICTURE ASSET*/
import ICON_BACK from "../../Assets/jsx/ICON_BACK";

/*GENERAL FUNCTION & DATA*/
import sort_array from "../../App/validation/sort_array";
import dict from "../../Data/dict.json";
import colors_poi_tipe_1 from "../../Data/colors_poi_tipe_1.json";

/*NON IMPORT*/

class SINI_POI_FULL extends Component {
  state = {
    mode_tipe: "tipe_1",
    anchor: null,
    modal_layer_config: false,
  };

  toggle_layer_config = (e) => {
    this.setState({ modal_layer_config: !this.state.modal_layer_config });
    if (e) {
      this.setState({ anchor: e.currentTarget });
    }
  };

  on_set_sini_menu_active = () => {
    this.props.set_value_sini({
      key: "sini_menu_active",
      value: "",
    });
    this.props.set_value_sini({
      key: "sini_map_show",
      value: ["poi", "isochrone"],
    });
  };

  on_set_mode_tipe = (mode_tipe) => {
    this.setState({
      mode_tipe,
    });
  };

  render() {
    //local storage
    const language = localStorage?.language || "ina";

    //local state
    const { mode_tipe, modal_layer_config, anchor } = this.state;

    //global props
    const { sini_v2_list, request_id_active, ai_parent_detail_list } =
      this.props.sini;
    const { loading_item_array } = this.props.loading_reducer;

    //content
    const merge_array = [...sini_v2_list, ...ai_parent_detail_list];
    const is_loading_poi = loading_item_array.includes("poi");
    const sini_object = merge_array.find(
      (item) => item.request_id === request_id_active
    );
    const poi_features = sini_object?.poi_features || [];
    let poi_resume_1 = sini_object?.poi_resume_1 || [];
    let poi_resume_2 = sini_object?.poi_resume_2 || [];
    let poi_resume_3 = sini_object?.poi_resume_3 || [];

    poi_resume_1 = sort_array(poi_resume_1, "number", false);
    poi_resume_2 = sort_array(poi_resume_2, "number", false);
    poi_resume_3 = sort_array(poi_resume_3, "number", false);

    let content;
    if (is_loading_poi || poi_features.length > 0 || poi_resume_1.length > 0) {
      const max_tipe_1 = poi_resume_1?.[0]?.number;

      content = (
        <section
          className="sini_full_scroll_area"
          style={{ paddingRight: "10px" }}
        >
          {is_loading_poi && (
            <div className="badge background_green margin_bottom margin_right">
              {dict?.["Loading data"]?.[language]}...
            </div>
          )}
          <br />
          <p
            className={`button_big margin_bottom text_left margin_right ${
              mode_tipe === "tipe_1"
                ? "background_black"
                : "background_light_grey"
            }`}
            onClick={this.on_set_mode_tipe.bind(this, "tipe_1")}
            data-mapid="clickTipe1"
          >
            TIPE 1
          </p>
          <p
            className={`button_big margin_bottom text_left margin_right ${
              mode_tipe === "tipe_2"
                ? "background_black"
                : "background_light_grey"
            }`}
            onClick={this.on_set_mode_tipe.bind(this, "tipe_2")}
            data-mapid="clickTipe2"
          >
            TIPE 2
          </p>
          <p
            className={`button_big margin_bottom text_left margin_right ${
              mode_tipe === "tipe_3"
                ? "background_black"
                : "background_light_grey"
            }`}
            onClick={this.on_set_mode_tipe.bind(this, "tipe_3")}
            data-mapid="clickTipe3"
          >
            TIPE 3
          </p>
          {poi_resume_1.map((element, idx) => {
            const color =
              colors_poi_tipe_1?.find(
                (color_element) => color_element?.name === element?.name
              )?.color || "#dedede";

            let formated_number;
            if (element.number) {
              formated_number = parseInt(element.number);
              formated_number = new Intl.NumberFormat("id-ID", {
                style: "decimal",
              }).format(formated_number);
            }
            let child_content;
            if (mode_tipe === "tipe_2") {
              const array_child = poi_resume_2.filter(
                (item) => item.parent === element.name
              );
              const max_tipe_2 = array_child?.[0]?.number;
              child_content = (
                <main className="container_light margin_top">
                  <p className="text_small">Tipe 2:</p>
                  {array_child.map((child, idx) => {
                    let formated_number;
                    if (child.number) {
                      formated_number = parseInt(child.number);
                      formated_number = new Intl.NumberFormat("id-ID", {
                        style: "decimal",
                      }).format(formated_number);
                    }
                    return (
                      <section
                        key={idx}
                        className="button_transparent"
                        style={{
                          paddingBottom: "10px",
                          paddingTop: "10px",
                        }}
                      >
                        <div className="parent_half text_small">
                          <div className="left_half text_left full_width">
                            {child.name}
                          </div>
                          <div className="right_half text_right">
                            {formated_number}
                          </div>
                        </div>
                        <ProgressBar
                          current_number={child.number}
                          total_number={max_tipe_2}
                          color={color}
                          is_hide_text={true}
                        />
                        {child_content}
                      </section>
                    );
                  })}
                </main>
              );
            } else if (mode_tipe === "tipe_3") {
              const array_child = poi_resume_3.filter(
                (item) => item.parent_tipe_1 === element.name
              );
              const max_tipe_3 = array_child?.[0]?.number;
              child_content = (
                <main className="container_light margin_top">
                  <p className="text_small">Tipe 3:</p>
                  {array_child.map((child, idx) => {
                    let formated_number;
                    if (child.number) {
                      formated_number = parseInt(child.number);
                      formated_number = new Intl.NumberFormat("id-ID", {
                        style: "decimal",
                      }).format(formated_number);
                    }
                    return (
                      <section
                        key={idx}
                        className="button_transparent"
                        style={{
                          paddingBottom: "10px",
                          paddingTop: "10px",
                        }}
                      >
                        <div className="parent_half text_small">
                          <div className="left_half text_left full_width">
                            {child.name}
                          </div>
                          <div className="right_half text_right">
                            {formated_number}
                          </div>
                        </div>
                        <ProgressBar
                          current_number={child.number}
                          total_number={max_tipe_3}
                          color={color}
                          is_hide_text={true}
                        />
                        {child_content}
                      </section>
                    );
                  })}
                </main>
              );
            }
            return (
              <section
                key={idx}
                className="button_transparent"
                style={{
                  paddingBottom: "10px",
                  paddingTop: "10px",
                }}
              >
                <div className="parent_half text_small">
                  <p className="left_half text_left full_width text_bold">
                    {element.name}
                  </p>
                  <div className="right_half text_right">{formated_number}</div>
                </div>
                <ProgressBar
                  current_number={element.number}
                  total_number={max_tipe_1}
                  color={color}
                  is_hide_text={true}
                />
                {child_content}
              </section>
            );
          })}
        </section>
      );
    }

    const modal_layer_config_content = modal_layer_config && (
      <Menu
        id="basic-menu"
        anchorEl={anchor}
        open={modal_layer_config}
        onClose={this.toggle_layer_config}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <div
          style={{
            padding: "7px 15px 0px 15px",
            width: "250px",
          }}
        >
          <SINI_POINT_CONFIG />
        </div>
      </Menu>
    );

    return (
      <main className="sini_full_parent">
        {modal_layer_config_content}
        <section className="sini_full_header">
          <table>
            <tbody>
              <tr>
                <td
                  style={{ width: "30px" }}
                  onClick={this.on_set_sini_menu_active}
                  data-mapid="clickBack"
                >
                  <ICON_BACK />
                </td>
                <td className="text_left">
                  <div className="badge background_green margin_bottom margin_right">
                    Point of Interest
                  </div>
                  <div
                    className="badge background_green margin_bottom margin_right cursor_pointer"
                    onClick={this.toggle_layer_config}
                    data-mapid="clickEdit"
                  >
                    Edit tampilan
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </section>
        {content}
      </main>
    );
  }
}

const mapStateToProps = (state) => ({
  sini: state.sini,
  loading_reducer: state.loading_reducer,
});

export default connect(mapStateToProps, { set_value_sini })(SINI_POI_FULL);
