/*LIBRARY MODULE*/
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Grid, Tooltip } from '@material-ui/core';
import io from 'socket.io-client';

/*PERSONAL COMPONENT*/
import Login from '../auth/Login';
import Register from '../auth/Register';
import Modal from '../common_modal/Modal';
import PaymentTable from '../payment/PaymentTable';
import PieChart from '../viewer_chart/PieChart';
import IotStatisticBrantas from '../viewer_trivial/iotStatisticBrantas';
import SidebarPCH from '../viewer_trivial/SidebarPCH';
import SidebarPDA from '../viewer_trivial/SidebarPDA';
import SIDEBAR_BANJIR from '../viewer_trivial/SIDEBAR_BANJIR';
import Filter from './Filter';
import CountingChart from '../viewer_chart/CountingChart';
import TimeseriesKAI from './TimeseriesKAI';
import TimeseriesResume from './TimeseriesResume';
import TimeseriesMonth from './TimeseriesMonth';
import TimeseriesArrayItem from './TimeseriesArrayItem';
import TimeseriesBiop from './TimeseriesBiop';
import CUSTOM_CHART from './CUSTOM_CHART';
// import NOTIFICATION from './NOTIFICATION';
import ONLINE_EDITORS from './ONLINE_EDITORS';
import PARENT_SINI from '../sini_v2/PARENT_SINI';
import TOOLBOX_TABLE from '../viewer_trivial/TOOLBOX_TABLE';

/*REDUX FUNCTION*/
import { resetFilter, filter_layer } from '../../App/actions/layerActions';
import {
  set_feature,
  grid_cilicis,
  set_value_layer,
} from '../../App/actions/dataActions';
import {
  setPOIFilter,
  getDetailLayerById,
  getLayerOnly,
  clearToolboxFeatures,
} from '../../App/actions/layerNewActions';
import { openModal } from '../../App/actions/authActions';

/*PICTURE ASSET*/

/*GENERAL FUNCTION & DATA*/
import isEmpty from '../../App/validation/is-empty';
import getStatusMember from '../../App/validation/get-status-member';
import dict from '../../Data/dict.json';
import st_colors from '../../Data/st_colors.json';
import DownloadExcel from '../../App/validation/DownloadExcel';
import Downloadgeojson from '../../App/validation/download_geojson';
import RIVER_SCHEMA from '../river_schema/RIVER_SCHEMA';

/*NON IMPORT*/
//const SOCKET_URL_MAPID = "http://localhost:5000";
const SOCKET_URL_MAPID = 'https://socket.mapid.io';

class LayerDetailStatistic extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fields_number: [],
      grid_number: [],
      fields_main: [],
      grid_main: [],
      chart_data: [],
      chart_name: [],
      csvData: [],
      features: [],
      sub_title: [],
      geo_layer: {},
      param_filter: [],
      color_set: {
        label_array: [],
        color_array: [],
      },
      feature_filter: [],
      modal_login: false,
      loginStatus: true,
      mode: 'toolbox',
      modal_pricing: false,
      modal_invite: false,
      // new
      startDate: `${this.formatDate(new Date())}`,
      finishDate: `${this.formatDate(new Date())}`,

      layer_id: '',
      chart_status: false,
      editorUsers: [],
    };
    this.chartReference = React.createRef();
    this.lineChartReference = React.createRef();
    this.socket = io.connect(SOCKET_URL_MAPID, {
      secure: true,
      reconnection: true,
      rejectUnauthorized: false,
    });
  }

  componentDidMount() {
    this.on_start();
  }

  componentDidUpdate(prevProps) {
    if (this.state.features !== prevProps.layer.features) {
      this.chartReference = React.createRef();
    }
    if (this.props.auth.isAuthenticated !== prevProps.auth.isAuthenticated) {
      this.setState({ modal_login: false });
    }
    if (this.props.auth.isRegistered !== prevProps.auth.isRegistered) {
      this.setState({ loginStatus: true });
    }

    if (prevProps.layer?.layer_id !== this.props.layer?.layer_id) {
      this.setState({
        fields_number: [],
        grid_number: [],
        fields_main: [],
        grid_main: [],
        chart_data: [],
        chart_name: [],
        csvData: [],
        features: [],
        sub_title: [],
        geo_layer: {},
        param_filter: [],
        color_set: {
          label_array: [],
          color_array: [],
        },
        feature_filter: [],
        modal_login: false,
        loginStatus: true,
        mode: 'toolbox',
        modal_pricing: false,
        modal_invite: false,
        startDate: `${this.formatDate(new Date())}`,
        finishDate: `${this.formatDate(new Date())}`,

        layer_id: '',
        chart_status: false,
      });

      const currentLayerId = this.props.layer?.layer_id;
      const prevLayerId = prevProps.layer?.layer_id;
      const { _id, full_name, name, email, profile_picture } =
        this.props.auth.user;

      if (prevLayerId !== currentLayerId) {
        if (prevLayerId) {
          this.socket.off(prevLayerId);
        }

        if (currentLayerId) {
          this.setState({
            editorUsers: [
              {
                topicId: currentLayerId,
                users: [
                  {
                    _id: _id,
                    name: name,
                    full_name: full_name,
                    email: email,
                    profile_picture: profile_picture,
                    idLayer: currentLayerId,
                    borderColor: '#ABE890',
                  },
                ],
              },
            ],
          });

          this.socket.on(currentLayerId, (data) => {
            this.setState((prevState) => {
              const editorUsers = prevState.editorUsers || [];
              const existingTopicIndex = editorUsers.findIndex(
                (topic) => topic.topicId === currentLayerId
              );

              if (data === null) {
                if (existingTopicIndex !== -1) {
                  const updatedEditorUsers = [...editorUsers];
                  updatedEditorUsers.splice(existingTopicIndex, 1);
                  return { editorUsers: updatedEditorUsers };
                }
              } else {
                if (existingTopicIndex === -1) {
                  return {
                    editorUsers: [
                      ...editorUsers,
                      { topicId: currentLayerId, users: data },
                    ],
                  };
                } else {
                  const updatedEditorUsers = [...editorUsers];
                  updatedEditorUsers[existingTopicIndex] = {
                    ...updatedEditorUsers[existingTopicIndex],
                    users: data,
                  };
                  return { editorUsers: updatedEditorUsers };
                }
              }

              return null;
            });
          });
        } else {
          // Jika tidak ada layerId
          this.setState({ editorUsers: [] });
          if (prevLayerId) {
            this.socket.off(prevLayerId); // Hapus listener jika layerId dihapus
          }
        }
      }
    }
  }

  on_start = () => {
    this.chartReference.current = [];
    const { layer_id, geo_layer_list } = this.props.layer;
    const geo_layer = geo_layer_list.find(
      (e) => e?.geo_layer?._id === layer_id
    );

    let fields = geo_layer?.geo_layer?.fields || [];
    fields = fields.filter((item) => !item.isStyle);
    const features = geo_layer?.geo_layer?.geojson?.features?.length || [];

    let sub_title = [];
    if (!features.length) {
      return {
        fields_number: [],
        grid_number: [],
        fields_main: [],
        grid_main: [],
        chart_data: [],
        chart_name: [],
        csvData: [],
        features: [],
        sub_title: [],
      };
    }
    const type = geo_layer?.geo_layer?.type;
    let new_fields = fields.filter((field) => field.isHide === false);
    let csvData = features.map((ft) => {
      let obj = {};
      if (type === 'Point') {
        let isUserExist = ft.user;
        obj['Reporter Name'] = isUserExist ? ft.user.full_name : '';
        obj['Reporter Phone Number'] = isUserExist ? ft.user.phone_number : '';
        obj['Reporter Email'] = isUserExist ? ft.user.email : '';
        obj['Latitude'] = ft.geometry.coordinates[1];
        obj['Longitude'] = ft.geometry.coordinates[0];
      }
      for (const key of new_fields) {
        obj[key.name] = ft.properties[key.key];
      }
      return obj;
    });
    new_fields = new_fields.filter((field) => field.type !== 'image');
    const fields_number = new_fields.filter(
      (field) =>
        field.type === 'number' ||
        field.type === 'currency' ||
        field.type === 'range'
    );
    const grid_number = features.map(({ properties, geometry }, idx) => {
      let objectPerIndex = fields_number.map(({ key }, idx) => {
        return properties && properties[key] ? Number(properties[key]) : 0;
      });
      return objectPerIndex;
    });
    const fields_main = new_fields.filter(
      (field) => field.isHighlight === true
    );
    if (fields_main.length > 0) {
      sub_title.push(fields_main[0].name);
      sub_title.push(features[0].properties[fields_main[0].key]);
    }
    const grid_main = features.map(({ properties, geometry }, idx) => {
      let objectPerIndex = fields_main.map(({ key }, idx) => {
        return properties && properties[key] ? properties[key] : '';
      });
      return objectPerIndex;
    });
    let color_set = [];
    new_fields = new_fields.filter(
      (x) =>
        x.type === 'selection' ||
        x.type === 'date' ||
        x.array_templates.length > 0
    );
    let array_color = [];
    let list_column = new_fields.map((f) => {
      const x = features
        .map((d) => d.properties[f.key])
        .sort()
        .filter((val, id, array) => {
          return array.indexOf(val) === id;
        });
      return x.length;
    });
    let max_fields_len = Math.max.apply(null, list_column);
    let i = 0;
    for (i = 0; i < max_fields_len; i++) {
      let index = i;
      if (i > st_colors.length - 1) {
        index = i % st_colors.length;
      }
      array_color.push(st_colors[index]);
    }
    const chart_data = new_fields.map((f) => {
      let column = features
        .map((d) => d.properties[f.key])
        .sort()
        .filter((val, id, array) => {
          return array.indexOf(val) === id;
        });
      let data = column.map((cl) => {
        let arr = features.filter((d) => d.properties[f.key] === cl);
        return arr.length;
      });
      let color = column.map((c, idx) => array_color[idx]);
      color_set.push({
        color_array: color.slice(),
        label_array: column.slice(),
        key: f.key,
      });
      return {
        key: f.key,
        name: f.name,
        data: {
          labels: column,
          datasets: [
            {
              data: data,
              backgroundColor: color,
              hoverBackgroundColor: color,
            },
          ],
        },
        type: f.type,
        field: f,
      };
    });

    this.setState({
      layer_id,
      fields_number,
      grid_number,
      chart_data,
      fields_main,
      grid_main,
      csvData,
      features,
      sub_title,
      color_set,
    });
  };

  on_group_all = () => {
    const { layer_id, geo_layer_list } = this.props.layer;
    const geo_layer = geo_layer_list.find(
      (e) => e?.geo_layer?._id === layer_id
    );

    let fields = geo_layer?.geo_layer?.fields || [];
    fields = fields.filter((item) => !item.isStyle);
    const features = geo_layer?.geo_layer?.geojson?.features || [];

    let color_set = [];

    let array_color = [];
    let list_column = fields.map((f) => {
      const x = features
        .map((d) => d.properties[f.key])
        .sort()
        .filter((val, id, array) => {
          return array.indexOf(val) === id;
        });
      return x.length;
    });
    let max_fields_len = Math.max.apply(null, list_column);
    let i = 0;
    for (i = 0; i < max_fields_len; i++) {
      let index = i;
      if (i > st_colors.length - 1) {
        index = i % st_colors.length;
      }
      array_color.push(st_colors[index]);
    }
    const chart_data = fields.map((f) => {
      let column = features
        .map((d) => d.properties[f.key])
        .sort()
        .filter((val, id, array) => {
          return array.indexOf(val) === id;
        });
      let data = column.map((cl) => {
        let arr = features.filter((d) => d.properties[f.key] === cl);
        return arr.length;
      });
      let color = column.map((c, idx) => array_color[idx]);
      color_set.push({
        color_array: color.slice(),
        label_array: column.slice(),
        key: f.key,
      });
      return {
        key: f.key,
        name: f.name,
        data: {
          labels: column,
          datasets: [
            {
              data: data,
              backgroundColor: color,
              hoverBackgroundColor: color,
            },
          ],
        },
        type: f.type,
        field: f,
      };
    });

    this.setState({
      layer_id,
      chart_data,
      features,
      color_set,
    });
  };

  toggleChartStatus = () => {
    this.setState({ chart_status: !this.state.chart_status }, () => {
      this.on_start();
    });
  };

  formatDate(date) {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [year, month, day].join('-');
  }

  downloadNameCsv = () => {
    const { geo_layer_list, layer_id } = this.props.layer;
    let name = '';
    geo_layer_list.map((d) =>
      d.geo_layer._id === layer_id ? (name = d.geo_layer.name) : null
    );
    return name;
  };

  toggle_login = () => {
    this.setState({ modal_login: !this.state.modal_login });
  };

  toggle_pricing = () => {
    this.setState({ modal_pricing: !this.state.modal_pricing });
  };

  toggle_invite = () => {
    this.setState({ modal_invite: !this.state.modal_invite });
  };

  toggleLoginRegister = () => {
    this.setState({ loginStatus: !this.state.loginStatus });
  };

  onOpenModal = (name) => {
    this.props.openModal(name);
  };

  clearFeatures = () => {
    const { geo_project } = this.props.project;
    const geo_project_link = this.props.geo_project_link;
    const { data_view_status } = this.props.layer;
    const { _id, link } = this.props.layer?.geo_layer?.geo_layer;
    if (geo_project_link !== 'data') {
      try {
        if (data_view_status) {
          this.props.setPOIFilter(['!=', 'id', '-1']);
          return;
        }
        this.props.resetFilter({ geo_project_link, link });
        if (_id) {
          const body = {
            geo_layer_id: _id,
            geo_project_id: geo_project._id,
            skipLoading: true,
          };
          this.props.getDetailLayerById(body);
        }
      } catch (e) {}
    } else {
      this.props.resetFilter({ geo_project_link, link });
      this.props.getLayerOnly(link);
    }
  };

  handleStarDate = (e) => {
    this.setState({ startDate: e.target.value });
  };

  handleFinishDate = (e) => {
    this.setState({ finishDate: e.target.value });
  };

  handleFilterDate = () => {
    const startDate = new Date(this.state.startDate).getTime();
    const finishDate = new Date(this.state.finishDate).getTime();
    let features = this.props.layer.geo_layer.geo_layer.filtered_features;
    features = features.filter((f) => {
      const date_in_unix = new Date(
        f?.properties?.Tanggal ||
          f?.properties?.Waktu_Pengambilan_Dokumentasi ||
          f?.date_in
      ).getTime();
      return date_in_unix >= startDate && date_in_unix <= finishDate;
    });
    this.props.filter_layer(features);
  };

  style = {
    center: { display: 'flex', justifyContent: 'center', alignItems: 'center' },
    font: { color: 'black' },
    fontMobile: { color: 'black' },
    input: {
      date: {
        borderTop: 'none',
        borderRight: 'none',
        borderLeft: 'none',
        borderRadius: 0,
      },
    },
    btn: {
      outline: {
        color: '#757575',
        padding: '0.35rem',
        marginLeft: '0.1rem',
        marginRight: '0.1rem',
        cursor: 'pointer',
      },
      active: {
        border: 'solid 1.8px #2196f3',
        backgroundColor: '#2196f3',
        color: '#fff',
        padding: '0.35rem',
        marginLeft: '0.1rem',
        marginRight: '0.1rem',
        borderRadius: '0.3rem',
        cursor: 'pointer',
      },
    },
  };

  render() {
    //local storage
    const language = localStorage?.language ? localStorage?.language : 'ina';

    //local state
    const {
      modal_login,
      loginStatus,
      modal_pricing,
      modal_invite,
      chart_data,
      sub_title,
    } = this.state;

    //global props
    const { isAuthenticated, isRegistered, domain } = this.props.auth;
    let { loadingDetail, layer_id, geo_layer_list, statistic_mode } =
      this.props.layer;
    const { folder_kai_timeseries, shared_link_check } = this.props.properties;
    const { sini_choose_map_active } = this.props.sini;

    //content
    const role = shared_link_check?.role;

    //layer utama
    let geo_layer = geo_layer_list.find((e) => e?.geo_layer?._id === layer_id);
    geo_layer = geo_layer?.geo_layer || {};

    //pengambilan beberapa atribut dari layer utama
    const timeseries_object = geo_layer?.timeseries_object || {};
    const timeseries_mode = timeseries_object?.timeseries_mode;

    let fields_date = geo_layer?.fields ? geo_layer?.fields : [];
    fields_date = fields_date.filter((f) => f.type === 'date');

    const features_filtered = geo_layer?.geojson_filtered?.features || [];
    const fields = geo_layer?.fields || [];

    let fields_number = fields.filter(
      (f) => ['number', 'currency'].includes(f?.type) && !f.isStyle
    );

    fields_number = fields_number.map((f) => {
      //STEP 1 generate array value for each field
      const value_array = features_filtered.map(
        (feature) => Number(feature?.properties?.[f?.key]) || 0
      );

      //STEP 2 sum the array of value using reduce
      const initial_value = 0;
      let sum_number = value_array.reduce(
        (accumulator, current_value) => accumulator + current_value,
        initial_value
      );
      if (sum_number > 10) {
        sum_number = parseInt(sum_number);
      } else {
        sum_number = parseFloat(sum_number).toFixed(2);
      }

      //STEP 3 average_number
      let average_number = sum_number / value_array.length;
      if (average_number > 10) {
        average_number = parseInt(average_number);
      } else {
        average_number = parseFloat(average_number).toFixed(2);
      }

      //STEP 4 set sum_number & average_number to field
      f.sum_number = sum_number;
      f.average_number = average_number;

      return f;
    });

    //get status user
    let is_author = false;

    if (isAuthenticated) {
      let userCurrentStatus;
      const { user } = this.props.auth;
      const { geo_project } = this.props.project;
      const groups = geo_project?.groups || [];
      if (!isEmpty(geo_project)) {
        userCurrentStatus = getStatusMember(groups, user?._id);
      }
      is_author =
        !!userCurrentStatus || !!(geo_project?.user?._id === user?._id);
    }

    let data_layer;

    if (statistic_mode === 'toolbox') {
      data_layer = <TOOLBOX_TABLE />;
    } else if (statistic_mode === 'sini' || sini_choose_map_active) {
      data_layer = <PARENT_SINI />;
    } else if (statistic_mode === 'river_schema') {
      data_layer = <RIVER_SCHEMA />;
    } else if (geo_layer?.type_2 === 'pch') {
      data_layer = <SidebarPCH />;
    } else if (geo_layer?.type_2 === 'pda') {
      data_layer = <SidebarPDA />;
    } else if (geo_layer?.type === 'IoT' || statistic_mode === 'iot') {
      if (
        ['bbwsbrantas.mapid.io'].includes(domain) ||
        this.props.auth.mode_balai === 'brantas' ||
        !isNaN(parseInt(domain))
      ) {
        data_layer = <IotStatisticBrantas />;
      }
    } else if (folder_kai_timeseries?._id) {
      // menampilkan resume hanya jika layer kai tidak sedang aktif, (layer_id && timeseries_mode === "kai")
      data_layer = <TimeseriesResume />;
    } else {
      const chart_content = loadingDetail ? null : (
        <main>
          <section className="text_header">
            {dict?.['Chart']?.[language]}
            {chart_data[0]?.type === 'date' ? (
              <section>
                <section className="text_bold" style={{ marginTop: '13px' }}>
                  {dict?.['Filter by']?.[language]}
                </section>
                <div className="filter_tanggal">
                  <Grid item>
                    <Tooltip
                      title={`${dict?.['Select Start Date']?.[language]}`}
                    >
                      <input
                        type="date"
                        style={this.style.input.date}
                        onChange={this.handleStarDate}
                        value={this.state.startDate}
                      />
                    </Tooltip>
                  </Grid>
                  <Grid item>
                    <Tooltip title={`${dict?.['Select End Date']?.[language]}`}>
                      <input
                        type="date"
                        style={this.style.input.date}
                        onChange={this.handleFinishDate}
                        value={this.state.finishDate}
                      />
                    </Tooltip>
                  </Grid>
                </div>
                <div className="button_filter">
                  <button className="button" onClick={this.handleFilterDate}>
                    {dict?.['Search']?.[language]}
                  </button>
                  <button
                    className="buttonSimple3"
                    onClick={(e) => {
                      this.props.layer.statistic_mode === 'toolbox'
                        ? this.props.clearToolboxFeatures()
                        : this.clearFeatures();
                    }}
                  >
                    {dict?.['Refresh']?.[language]}
                  </button>
                </div>
              </section>
            ) : null}
          </section>
          <PieChart
            chartReference={this.chartReference}
            chart_data={chart_data}
          />
        </main>
      );

      const download_button_excel = <DownloadExcel />;
      const download_button_json = <Downloadgeojson />;

      /*
      properties yang diambil ketika type_2 === banjir:

      Tanggal
      Pukul
      Kelurahan
      Kecamatan
      KotaKabupaten
      Prov
      Sungai_Terdekat

      Foto_kejadian_1
      Foto_kejadian_2

      Ketinggian_Genangan_cm
      */

      data_layer = (
        <main>
          <section className="text_inferior" style={{ marginBottom: '10px' }}>
            {dict?.['Data Insight']?.[language]}
            <button
              className="button_very_small"
              id="blue"
              onClick={this.onOpenModal.bind(this, 'Analyze Lite')}
              style={{ marginLeft: '10px' }}
            >
              i
            </button>
          </section>
          <section>
            {geo_layer?.name && (
              <div
                style={{
                  maxHeight: '126px',
                  overflow: 'hidden',
                }}
              >
                <h1 title={geo_layer?.name} className="text_header">
                  Layer: {geo_layer?.name}
                </h1>
              </div>
            )}

            <p
              className="text_inferior"
              style={{ whiteSpace: 'pre-wrap', lineBreak: 'strict' }}
            >
              {geo_layer?.description}
            </p>

            <p className="text_bold margin_bottom margin_top">
              Project:{' '}
              {this.props.project?.geo_project?.name
                ? this.props.project?.geo_project?.name
                : this?.props?.layer?.geo_layer?.geo_project?.name}
            </p>
          </section>

          <ONLINE_EDITORS
            editorUsers={this.state.editorUsers[0]}
            _id={geo_layer?._id}
          />
          <Filter />
          <CUSTOM_CHART />
          {timeseries_mode === 'kai' && <TimeseriesKAI />}
          {timeseries_mode === 'kai_income_farebox' && <TimeseriesMonth />}
          {(timeseries_mode === 'kai_komponen_pendapatan_tiket' ||
            timeseries_mode === 'kai_pendapatan_non_farebox') && (
            <TimeseriesArrayItem />
          )}
          {timeseries_mode === 'kai_biop' && <TimeseriesBiop />}
          {fields_date?.length > 0 && (
            <CountingChart field_date={fields_date[0]} />
          )}
          <p className="text_medium">
            {sub_title.length > 0 && sub_title[0] + ' : ' + sub_title[1]}
          </p>

          {/* <NOTIFICATION geo_layer={geo_layer} auth={this.props.auth} /> */}
          {geo_layer.type_2 !== 'banjir' ? (
            <main>
              <section className="margin_bottom">
                <p className="text_header">Sum</p>
                <table className="table_lite">
                  <tbody>
                    {fields_number.map((field, idx) => {
                      return (
                        <tr key={idx}>
                          <td className="text_inferior">{field?.name}</td>
                          <td className="text_bold">
                            {new Intl.NumberFormat('id-ID', {
                              style: 'decimal',
                            }).format(field?.sum_number)}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </section>

              <section className="margin_bottom">
                <p className="text_header">Average</p>
                <table className="table_lite">
                  <tbody>
                    {fields_number.map((field, idx) => {
                      return (
                        <tr key={idx}>
                          <td className="text_inferior">{field?.name}</td>
                          <td className="text_bold">
                            {new Intl.NumberFormat('id-ID', {
                              style: 'decimal',
                            }).format(field?.average_number)}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </section>
            </main>
          ) : (
            <SIDEBAR_BANJIR />
          )}
          <section>
            <button
              id={this.state.chart_status ? 'outline_blue' : 'outline_white'}
              className="button_standard"
              onClick={() => this.toggleChartStatus()}
              style={{ marginTop: 50, marginBottom: 20 }}
            >
              {this.state.chart_status
                ? dict['Hide Chart'][language]
                : dict['Show Chart'][language]}
            </button>
            {this.state.chart_status && (
              <main>
                <button
                  className="button"
                  id="blue"
                  onClick={this.on_group_all}
                >
                  Grouping semua kolom
                </button>
                <br />
                {chart_content}
              </main>
            )}
          </section>
          {(is_author || role === 'viewer_download' || role === 'editor') && (
            <section className="two-container-input">
              <div
                style={{
                  textAlign: 'center',
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                {download_button_excel}
              </div>
              <div
                style={{
                  textAlign: 'center',
                  display: 'flex',
                  alignItems: 'center',
                }}
              ></div>
              <div
                style={{
                  textAlign: 'center',
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                {download_button_json}
              </div>
            </section>
          )}
        </main>
      );
    }

    const modal_login_content = modal_login && (
      <Modal
        modalSize="small"
        id="modal"
        isOpen={modal_login}
        onClose={this.toggle_login}
      >
        <div
          className="box-body"
          style={{ marginTop: '30px', marginBottom: '30px' }}
        >
          {loginStatus || isRegistered ? <Login isNav={true} /> : <Register />}
          {loginStatus || isRegistered ? (
            <div style={{ display: 'block', marginTop: '30px' }}>
              <div className="button_account">
                {dict?.["Don't have an account?"]?.[language]}
              </div>
              <div
                className="button_register"
                onClick={this.toggleLoginRegister}
              >
                {dict?.['Register here']?.[language]}
              </div>
            </div>
          ) : (
            <div style={{ display: 'block', marginTop: '30px' }}>
              <div className="button_account">
                {dict?.['Aready have an account?']?.[language]}
              </div>
              <div
                className="button_register"
                onClick={this.toggleLoginRegister}
              >
                {dict?.['Login instead']?.[language]}
              </div>
            </div>
          )}
        </div>
      </Modal>
    );

    const invite_content = (
      <main style={{ textAlign: 'center' }}>
        <section className="text_bold" style={{ marginBottom: '50px' }}>
          {dict?.['Subscribe to MAPID to use SINI']?.[language]}
        </section>
        <button className="button_standard" onClick={this.toggle_invite}>
          {dict?.['Select subscription']?.[language]}
        </button>
      </main>
    );

    const payment_content = (
      <main>
        <main style={{ textAlign: 'center' }}>
          <section className="text_bold">
            {dict?.['Subscribe to MAPID to use SINI']?.[language]}
          </section>
        </main>
        <PaymentTable />
      </main>
    );

    const modal_pricing_content = modal_pricing && (
      <Modal
        modalSize="medium"
        id="modal"
        isOpen={modal_pricing}
        onClose={this.toggle_pricing}
      >
        <div className="box-body">
          {modal_invite ? payment_content : invite_content}
        </div>
      </Modal>
    );

    return (
      <>
        {modal_login_content}
        {modal_pricing_content}
        <main
          style={{
            borderSpacing: 0,
            width: '100%',
          }}
        >
          <section>{data_layer}</section>
        </main>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  project: state.project,
  layer: state.layer,
  auth: state.auth,
  payment: state.payment,
  map: state.map,
  properties: state.properties,
  sini: state.sini,
});

export default connect(mapStateToProps, {
  resetFilter,
  getDetailLayerById,
  openModal,
  setPOIFilter,
  getLayerOnly,
  clearToolboxFeatures,
  filter_layer,
  set_feature,
  grid_cilicis,
  set_value_layer,
})(LayerDetailStatistic);
